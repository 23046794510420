<template>
  <table :class="classes">
    <thead>
    <slot></slot>
    </thead>
    <tbody>
    <tr v-if="!elementsPage.length" class="p-4 text-center">
      <td colspan="30">Список пуст...</td>
    </tr>
    <component v-for="(row, i) in elementsPage" :row="row" :key="i" :is="currentComponent"/>
    </tbody>
  </table>
  <pagination-list v-model="page" :list="list"/>
</template>

<script>
// https://www.telerik.com/blogs/dynamic-components-vue-component
import OrderRow from '@/components/orders/OrderRow'
import ReturnRow from '@/components/returns/ReturnRow'
import BrandRow from '@/components/settings/BrandRow'
import PaginationList from '@/components/PaginationList'
import store from '@/store'

export default {
  name: 'TableComponent',
  components: {
    PaginationList,
  },
  props: ['list', 'type', 'class'],
  data () {
    return {
      page: 1,
    }
  },
  computed: {
    elementsPage () {
      return this.list.filter((item, i) => {
        const max = this.page * store.state.paginationConfig.itemsPerPage,
            min = max - store.state.paginationConfig.itemsPerPage - 1

        return !(i <= min || i >= max)
      })
    },
    currentComponent () {
      if (this.type === 'order') {
        return OrderRow
      }
      else if (this.type === 'return') {
        return ReturnRow
      }
      return BrandRow
    },
    classes () {
      let classes = ['table-inside']
      if (this.class) {
        classes.push(this.class)
      }
      return classes
    }
  },
}
</script>
