<template>
  <h1>Настройки</h1>
  <div class="inside inside--h-full md:flex md:space-x-12">
    <div class="md:w-2/12 w-full mb-4 md:mb-0">
      <left-menu @input="changeComponent" :list="leftMenu"></left-menu>
    </div>
    <component :is="currentComponent" class="w-10/12 md:w-full"></component>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu'
import ProfileEdit from '@/components/settings/ProfileEdit'
import RequisitesEdit from '@/components/settings/RequisitesEdit'
import BrandsList from '@/components/settings/BrandsList'

export default {
  name: 'SettingsPage',
  components: { LeftMenu, ProfileEdit, RequisitesEdit, BrandsList },
  data() {
    return {
      currentComponent: ProfileEdit,
      leftMenu: [
        {
          title: 'Профиль',
          component: ProfileEdit
        },
        {
          title: 'Реквизиты',
          component: RequisitesEdit
        },
      ]
    }
  },
  methods: {
    changeComponent(value) {
      this.currentComponent = value
    }
  }
}
</script>
