<template>
  <h1>Смена пароля</h1>
  <form @submit.prevent="submit" class="form auth-form">
    <input v-model="checkWord" name="checkWord" type="hidden">
    <input v-model="login" name="login" type="hidden">
    <label class="mb-4">
      Пароль<br>
      <input v-model="password" @blur="blur($event, 'password')" :class="getClassInput('password')"
             type="password" name="password">
      <span class="form__error-message" v-if="v$.password.$error">Пароль должен быть не менее 6 символов</span>
    </label>
    <label class="mb-4">
      Подтверждение пароля<br>
      <input v-model="confirmPassword" @blur="blur($event, 'confirmPassword')" :class="getClassInput('confirmPassword')"
             type="password" name="confirmPassword">
      <span class="form__error-message" v-if="v$.confirmPassword.$error">Пароли не совпадают</span>
    </label>
    <button type="submit" class="base-button">Восстановить</button>
    <div class="text-center mt-2">
      <a @click="$emit('change-page', 'SignIn')" class="dashed">&larr;&nbsp;Авторизация</a>
    </div>
  </form>
</template>

<script>
//import { useVuelidate } from '@vuelidate/core/dist/index.esm'
import { minLength, required, sameAs } from '@vuelidate/validators'
import authHelper from '@/classes/formHelper'

import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'ChangePassword',
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  data () {
    return {
      password: '',
      confirmPassword: '',
      checkWord: '',
      login: '',
      url: '/user/codeCheckAndChangePassword',
    }
  },
  validations () {
    return {
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, minLength: minLength(6), sameAsPassword: sameAs(this.password) },
    }
  },
  methods: {
    blur: authHelper.blur,
    getClassInput: authHelper.getClassInput,
    submit: authHelper.submit,
  },
  async mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    this.checkWord = urlParams.get('USER_CHECKWORD')
    this.login = urlParams.get('USER_LOGIN')
  },
}
</script>
