<template>
  <ul class="nav-left">
    <li v-for="(item, index) in list" :key="index" @click="$emit('input', item.component); currentIndex = index" :class="getClasses(index)">
      <span>{{ item.title }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LeftMenu',
  props: ['list'],
  data() {
    return {
      currentIndex: 0
    }
  },
  methods: {
    getClasses(index) {
      return {
        'nav__child-link': true,
        'cursor-pointer': true,
        'active': index === this.currentIndex
      }
    }
  }
}
</script>
