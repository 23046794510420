<template>
  <v-pagination
      v-model="pageInside"
      :pages="paginationCalcPages(list)"
      :range-size="paginationConfig.rangeSize"
      :hideFirstButton="paginationConfig.hideFirstButton"
      :hideLastButton="paginationConfig.hideLastButton"
  />
</template>

<script>
import { mapState } from 'vuex'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'

export default {
  name: 'PaginationList',
  components: {
    VPagination
  },
  props: ['list', 'page'],
  data() {
    return {
      pageInside: 1
    }
  },
  computed: {
    ...mapState(['paginationConfig'])
  },
  watch: {
    pageInside(value) {
      this.$emit('input', value)
    }
  },
  methods: {
    // Высчитывает кол-во страниц, в соответствии с конфигурацией и кол-вом элементов
    paginationCalcPages() {
      if (!Array.isArray(this.list)) {
        return 0
      }

      if (!this.list.length) {
        return 0
      }

      return Math.ceil(this.list.length / this.paginationConfig.itemsPerPage)
    },
  }
}
</script>
