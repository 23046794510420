<template>
  <div class="flex flex-col items-start	">
    <h2 class="mb-4 text-2xl">Реквизиты</h2>
    <form @submit.prevent="submit($event, submitCallback)" class="form" autocomplete="off">
      <div v-if="requisites.checked" class="alert alert--success mb-4">
        Реквизиты компании успешно подтверждены.<br>
        После подтверждения компании изменить реквизиты можно только через службу технической поддержки.
      </div>
      <div class="form-block">
        <div class="form-block__info">
          Основное<br>
          <span class="form-block__desc">
            Шаг 1/4
          </span>
        </div>
        <div class="form-block__fields">
          <label class="mb-4">
            Тип организации<br>
            <Multiselect :value="typeOwnership" v-model="typeOwnership" :options="typesOwnership"
                         :disabled="requisites.checked" placeholder="-- Тип организации --" class="mt-1"/>
            <input :value="typeOwnership" type="hidden" name="typeOwnership">
            <input :value="requisites.id" type="hidden" name="id">
          </label>
          <label v-if="typeOwnership !== null" class="mb-4">
            ИНН<br>
            <input v-model="inn" :class="getClassInput('inn')" @blur="blur($event, 'inn')"
                   :disabled="requisites.checked" :maxlength="innLength" name="inn" type="text">
            <span class="form__error-message" v-if="v$.inn.$error">Некорректный ИНН</span>
          </label>
        </div>
      </div>

      <div v-if="mainCompleted" class="form-block">
        <div class="form-block__info">
          Реквизиты<br>
          <span class="form-block__desc">
            Шаг 2/4
          </span>
        </div>
        <div class="form-block__fields">
          <label v-if="checkOoo" class="mb-4">
            Название организации
            <input v-model="name" :disabled="requisites.checked" name="companyName" type="text" class="form__input">
          </label>
          <label class="mb-4">
            Фамилия
            <template v-if="checkOoo">генерального директора</template>
            <input v-model="ceoLastName" :disabled="requisites.checked" name="ceoLastName" type="text"
                   class="form__input">
          </label>
          <label class="mb-4">
            Имя
            <template v-if="checkOoo">генерального директора</template>
            <input v-model="ceoFirstName" :disabled="requisites.checked" name="ceoFirstName" type="text"
                   class="form__input">
          </label>
          <label class="mb-4">
            Отчество
            <template v-if="checkOoo">генерального директора</template>
            <input v-model="ceoSecondName" :disabled="requisites.checked" name="ceoSecondName" type="text"
                   class="form__input">
          </label>
          <div class="flex justify-between">
            <label v-if="checkOoo" class="mb-4">
              КПП<br>
              <input v-model="kpp" :disabled="requisites.checked" :class="getClassInput('kpp')"
                     @blur="blur($event, 'kpp')" :maxlength="kppLength" name="kpp" type="text" style="min-width: 180px">
              <template v-if="v$.kpp.$error">
                <br>
                <span class="form__error-message">Некорректный КПП</span>
              </template>
            </label>
            <label v-if="checkOoo" class="mb-4">
              ОГРН<br>
              <input v-model="bankOgrn" :disabled="requisites.checked" :class="getClassInput('bankOgrn')"
                     @blur="blur($event, 'bankOgrn')" :maxlength="ogrnLength" name="bankOgrn" type="text"
                     style="min-width: 180px">
              <template v-if="v$.bankOgrn.$error">
                <br>
                <span class="form__error-message">Некорректный ОГРН</span>
              </template>
            </label>
            <label v-else class="mb-4">
              ОГРНИП<br>
              <input v-model="bankOgrnip" :disabled="requisites.checked" :class="getClassInput('bankOgrnip')"
                     @blur="blur($event, 'bankOgrnip')" :maxlength="ogrnipLength" name="bankOgrnip" type="text">
              <template v-if="v$.bankOgrnip.$error">
                <br>
                <span class="form__error-message">Некорректный ОГРНИП</span>
              </template>
            </label>
          </div>
        </div>
      </div>

      <div v-if="requisitesCompleted" class="form-block">
        <div class="form-block__info">
          Банковские реквизиты<br>
          <span class="form-block__desc">
            Шаг 3/4
          </span>
        </div>
        <div class="form-block__fields">
          <label class="mb-4">
            Наименование банка<br>
            <input v-model="bankName" :disabled="requisites.checked" name="bankName" type="text" class="form__input">
          </label>
          <label class="mb-4">
            БИК<br>
            <input v-model="bankBik" :disabled="requisites.checked" :class="getClassInput('bankBik')"
                   @blur="blur($event, 'bankBik')" :maxlength="bikLength" name="bankBik" type="text">
            <span class="form__error-message" v-if="v$.bankBik.$error">Некорректный БИК</span>
          </label>
          <div class="flex justify-between">
            <label class="mb-4">
              Расчетный счет<br>
              <input v-model="bankRs" :disabled="requisites.checked" :class="getClassInput('bankRs')"
                     @blur="blur($event, 'bankRs')" :maxlength="rsLength" name="bankRs" type="text"
                     style="min-width: 180px">
              <template v-if="v$.bankRs.$error">
                <br>
                <span class="form__error-message">Некорректный р/с</span>
              </template>
            </label>
            <label class="mb-4">
              Корреспондентский счет<br>
              <input v-model="bankKs" :disabled="requisites.checked" :class="getClassInput('bankKs')"
                     @blur="blur($event, 'bankKs')" :maxlength="ksLength" name="bankKs" type="text"
                     style="min-width: 180px">
              <template v-if="v$.bankKs.$error">
                <br>
                <span class="form__error-message">Некорректный к/с</span>
              </template>
            </label>
          </div>
        </div>
      </div>

      <div v-if="bankCompleted" class="form-block">
        <div class="form-block__info">
          Адрес
          <template v-if="checkOoo">компании</template>
          <template v-else>регистрации</template>
          <br>
          <span class="form-block__desc">
            Шаг 4/4
          </span>
        </div>
        <div class="form-block__fields">
          <label class="mb-4">
            Область / Республика / Край<br>
            <input v-model="country" :disabled="requisites.checked" name="country" type="text" class="form__input">
          </label>
          <label class="mb-4">
            Город<br>
            <input v-model="city" :disabled="requisites.checked" name="city" type="text" class="form__input">
          </label>
          <label class="mb-4">
            Улица, номер дома<br>
            <input v-model="street" :disabled="requisites.checked" name="street" type="text" class="form__input">
          </label>
          <label class="mb-4">
            Индекс<br>
            <input v-model="zip" :disabled="requisites.checked" :class="getClassInput('zip')"
                   @blur="blur($event, 'zip')" :maxlength="zipLength" name="zip" type="text">
            <span class="form__error-message" v-if="v$.zip.$error">Некорректный индекс</span>
          </label>
        </div>
      </div>
      <div v-if="requisites.checked !== true" class="button-add">
        <button type="submit" class="base-button">Сохранить</button>
      </div>

    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, minLength, numeric, required } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import authHelper from '@/classes/formHelper'

export default {
  name: 'RequisitesEdit',
  components: {
    Multiselect,
  },
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  validations () {
    return {
      inn: { required, numeric, minLength: minLength(this.innLength), maxLength: maxLength(this.innLength) },
      kpp: {
        required: this.checkOoo,
        numeric,
        minLength: minLength(this.kppLength),
        maxLength: maxLength(this.kppLength),
      },
      bankOgrn: {
        required: this.checkOoo,
        numeric,
        minLength: minLength(this.ogrnLength),
        maxLength: maxLength(this.ogrnLength),
      },
      bankOgrnip: {
        required: !this.checkOoo,
        numeric,
        minLength: minLength(this.ogrnipMinLength),
        maxLength: maxLength(this.ogrnipLength),
      },
      bankBik: { required, numeric, minLength: minLength(this.bikLength), maxLength: maxLength(this.bikLength) },
      bankRs: { required, numeric, minLength: minLength(this.rsLength), maxLength: maxLength(this.rsLength) },
      bankKs: { required, numeric, minLength: minLength(this.ksLength), maxLength: maxLength(this.ksLength) },
      zip: { required, numeric, minLength: minLength(this.zipLength), maxLength: maxLength(this.zipLength) },
      addressCompleted: { checked: value => value === true },
    }
  },
  data () {
    return {
      loaded: false,
      warningMessage: 'Проверьте правильность заполнения всех полей',
      url: '/user/requisitesUpdate',
    }
  },
  computed: {
    ...mapState(['requisitesStore']),
    innLength () {
      return this.checkOoo ? 10 : 12
    },
    kppLength () {
      return 9
    },
    ogrnLength () {
      return 13
    },
    ogrnipMinLength () {
      return 12
    },
    ogrnipLength () {
      return 15
    },
    bikLength () {
      return 9
    },
    rsLength () {
      return 20
    },
    ksLength () {
      return 20
    },
    zipLength () {
      return 6
    },
    requisites () {
      return this.requisitesStore.requisites
    },
    typesOwnership () {
      return this.requisitesStore.typesOwnership
    },
    disabledSubmit () {
      return this.requisites.checked
    },
    typeOwnership: {
      get () {
        return this.requisites.typeOwnership
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'typeOwnership', value: value })
      },
    },
    inn: {
      get () {
        return this.requisites.inn
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'inn', value: value })
      },
    },
    name: {
      get () {
        return this.requisites.name
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'name', value: value })
      },
    },
    ceoLastName: {
      get () {
        return this.requisites.ceoLastName
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'ceoLastName', value: value })
      },
    },
    ceoFirstName: {
      get () {
        return this.requisites.ceoFirstName
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'ceoFirstName', value: value })
      },
    },
    ceoSecondName: {
      get () {
        return this.requisites.ceoSecondName
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'ceoSecondName', value: value })
      },
    },
    kpp: {
      get () {
        return this.requisites.kpp
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'kpp', value: value })
      },
    },
    bankOgrn: {
      get () {
        return this.requisites.bankOgrn
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'bankOgrn', value: value })
      },
    },
    bankOgrnip: {
      get () {
        return this.requisites.bankOgrnip
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'bankOgrnip', value: value })
      },
    },
    bankName: {
      get () {
        return this.requisites.bankName
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'bankName', value: value })
      },
    },
    bankBik: {
      get () {
        return this.requisites.bankBik
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'bankBik', value: value })
      },
    },
    bankRs: {
      get () {
        return this.requisites.bankRs
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'bankRs', value: value })
      },
    },
    bankKs: {
      get () {
        return this.requisites.bankKs
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'bankKs', value: value })
      },
    },

    country: {
      get () {
        return this.requisites.country
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'country', value: value })
      },
    },
    city: {
      get () {
        return this.requisites.city
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'city', value: value })
      },
    },
    street: {
      get () {
        return this.requisites.street
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'street', value: value })
      },
    },
    zip: {
      get () {
        return this.requisites.zip
      },
      set (value) {
        this.$store.commit('requisitesStore/setField', { key: 'zip', value: value })
      },
    },
    checkOoo () {
      return this.typeOwnership === this.requisitesStore.ooo
    },
    mainCompleted () {
      return !!this.inn && this.inn.length >= this.innLength && this.typeOwnership !== null
    },
    requisitesCompleted () {
      if (this.checkOoo) {
        return this.mainCompleted &&
            !!this.ceoLastName && !!this.ceoFirstName && !!this.ceoSecondName && !!this.name &&
            !!this.kpp && !!this.bankOgrn
      }

      return this.mainCompleted &&
          !!this.ceoLastName && !!this.ceoFirstName && !!this.ceoSecondName && !!this.bankOgrnip
    },
    bankCompleted () {
      return this.requisitesCompleted &&
          !!this.bankName && !!this.bankBik && !!this.bankRs && !!this.bankKs
    },
    addressCompleted () {
      return this.bankCompleted &&
          !!this.country && !!this.city && !!this.street && !!this.zip
    },
  },
  watch: {
    localPhone (value) {
      if (value === '8') {
        this.localPhone = '+7 ('
      }
      else if (value === '+7 (8') {
        this.localPhone = '+7 (9'
      }
    },
  },
  methods: {
    blur: authHelper.blur,
    getClassInput: authHelper.getClassInput,
    submit: authHelper.submit,
    submitCallback(data) {
      this.$store.commit('requisitesStore/setField', { key: 'id', value: data.companyId })
    }
  },
  async mounted () {
    await this.$store.dispatch('requisitesStore/getData').then(() => {
      this.loaded = true
      this.localPhone = this.requisites.tel
    })
  },
}
</script>

<style scoped>
.multiselect {
  border-radius: 5px;
}

.button-add {
  width: 760px;
  display: flex;
  justify-content: flex-end
}

@media (max-width: 820px) {
  .button-add {
    width: auto;
  }
}
</style>
