<template>
  <div class="flex flex-col items-start">
    <form @submit.prevent="submit($event, callbackAfterSendDocument)" class="form" autocomplete="off">
      <input v-if="editableBrand !== undefined" :value="editableBrand.id" name="id" type="hidden">
      <div class="form-block">
        <div class="form-block__info">
          Основное<br>
          <span class="form-block__desc">
            Данные документа
          </span>
        </div>
        <div class="form-block__fields">
          <label class="mb-4">
            Тип документы<br>
            <Multiselect :value="typeBrand" v-model="typeBrand" :options="types" placeholder="-- Тип документа --"
                         name="typeBrand" class="mt-1" :disabled="disabled"/>
            <input :value="typeBrand" name="typeBrand" type="hidden">
            <span class="form__error-message" v-if="v$.typeBrand.$error">Выберите тип документа</span>
          </label>
          <label class="mb-4">
            Название бренда<br>
            <input v-model="name" @blur="blur($event, 'name')" :disabled="disabled" name="name" type="text"
                   class="form__input">
            <span class="form__error-message" v-if="v$.name.$error">Обязательно для заполнени</span>
          </label>
          <div class="flex justify-between">
            <label class="mb-4">
              Выдан<br>
              <flat-pickr v-model="issued" :config="dateConfigBetween" name="issued" :disabled="disabled"/>
              <template v-if="v$.issued.$error">
                <br>
                <span class="form__error-message">Укажите дату</span>
              </template>
            </label>
            <label class="mb-4">
              Истекает<br>
              <flat-pickr v-model="expires" :config="dateConfigBetween" name="expires" :disabled="disabled"/>
              <template v-if="v$.expires.$error">
                <br>
                <span class="form__error-message">Укажите дату</span>
              </template>
            </label>
          </div>
        </div>
      </div>
      <div class="form-block">
        <div class="form-block__info">
          Файл<br>
          <span v-if="disabled !== true" class="form-block__desc">
            <template v-if="editableBrand !== undefined">
              Выбирайте новый файл только если хотите заменить ранее загруженный<br><br>
            </template>
            Формат – PDF, JPG, JPEG, PNG.<br>
            Размер – не больше 10 МБ.
          </span>
        </div>
        <div class="form-block__fields">
          <label v-if="disabled !== true" class="mb-4">
            Новый<br>
            <input name="file" type="file" accept=".pdf,.jpg,.jpeg,.png" class="mt-1">
          </label>
          <label v-if="editableBrand !== undefined">
            Загружен<br>
            <a :href="'http://kdstore.mac' + editableBrand.file" target="_blank">http://kdsotre.mac{{
                editableBrand.file
              }}</a>
            <br><br>
          </label>
        </div>
      </div>
      <div v-if="disabled !== true" style="width: 690px; display: flex; justify-content: flex-end">
        <button type="submit" class="base-button">Сохранить</button>
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/themes/dark.css'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import authHelper from '@/classes/formHelper'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'BrandEdit',
  components: { Multiselect, flatPickr },
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  data () {
    return {
      name: null,
      issued: null,
      expires: null,
      typeBrand: null,
      dateConfigBetween: {
        wrap: true,
        altFormat: 'd.m.Y',
        allowInput: true,
        altInput: true,
        dateFormat: 'Y-m-d',
        'locale': Russian,
        altInputClass: 'form__input',
      },
    }
  },
  validations () {
    return {
      name: { required },
      issued: { required },
      expires: { required },
      typeBrand: { required },
    }
  },
  computed: {
    ...mapState('brandsStore', ['types', 'statusDefault']),
    ...mapGetters('brandsStore', ['editableBrand']),
    url () {
      return !this.editableBrand ? '/brands/create' : '/brands/update'
    },
    disabled () {
      if (!this.editableBrand) {
        return false
      }
      return this.statusDefault !== this.editableBrand.status
    },
  },
  methods: {
    blur: authHelper.blur,
    getClassInput: authHelper.getClassInput,
    submit: authHelper.submit,
    callbackAfterSendDocument (data) {
      if (!this.editableBrand) {
        this.$store.commit('brandsStore/addItem', {
          id: data.id,
          name: this.name,
          issued: this.issued,
          expires: this.expires,
          typeBrand: this.typeBrand,
          status: data.status,
          file: data.file,
          dateCreate: data.dateCreate,
        })
        this.$store.commit('brandsStore/setEditable', 'list')
      }
    },
  },
  mounted () {
    if (this.editableBrand !== undefined) {
      this.name = this.editableBrand.name
      this.issued = this.editableBrand.issued
      this.expires = this.editableBrand.expires
      this.typeBrand = this.editableBrand.typeBrand
    }
  },
}
</script>

<style scoped>
.multiselect {
  border-radius: 5px;
}
</style>
