<template>
  <div>
    <h1>
      <span @click="$router.push('/')" class="h1__title">Главная</span>
      <i>&rarr;</i>
      <span class="h1__current">Забор/доставка груза</span>
    </h1>

    <div class="inside">
      <h2 class="mb-4 text-2xl">Заполните данные</h2>

      <form @submit.prevent="preSubmit($event, redirectToDetail)" class="form" autocomplete="off" enctype="multipart/form-data">
        <div class="form-block">
          <!-- ОСНОВНОЕ: левый блок -->
          <div class="form-block__info">
            <span class="form-block__desc">
              <b>Заполните своими словами информацию о грузе:</b><br>
              – адрес (максимально подробно);<br>
              – контакт (кто выдаст груз);<br>
              – что забираем (тип груза, количество мест).<br><br>

              *если груз забираем на «Южных воротах, ТЯК, Альфа Карго», потребуется транспортная накладная от вашего карго и информация об оплате услуг Карго.<br>
              *если груз к нам привезет ваша транспортная компания или водитель, укажите контакты водителя, дату и время.<br><br>

              <b>Документы:</b> загрузите все имеющиеся файлы и документы, которые помогут нам в реализации данного поручения.<br><br>

              <b>Дополнительные детали:</b> Любая текстовая информация (по необходимости).<br><br>
            </span>

            <a href="#">Посмотреть прайс</a><br>
            <a href="#">Посмотреть видеоинструкцию</a><br>
          </div>
          <!-- ОСНОВНОЕ: поля -->
          <div class="form-block__fields">
            <!-- Как необходимо упаковать? -->
            <label class="mb-4">
              Где забрать или Куда привезут?<br>
              <textarea v-model="point" @blur="blur($event, 'point')" :class="getClassInput('point')" name="point"></textarea>
              <span class="form__error-message" v-if="v$.point.$error">Заполните поле</span>
            </label>
            <!-- Документы -->
            <div class="mb-4">
              <label>Транспортные документы</label><br>
              <div v-for="index in marksCount" :key="index" class="form__input form__input-file">
                <label style="height: 100%;width: calc(100% - 40px);display: inline-block;">
                  <input @change="changeFileValue" name="realMark[]" class="hidden" type="file">
                  <span class="label">.zip или .pdf</span>
                </label>
                <span v-if="(index+1) === realMarks && realMarks > 1" class="remove" @click="realMarks--">x</span>
                <span v-else class="form__input-icon">
                  <svg class="pc-icon">
                    <use xlink:href="#custom-document-upload"></use>
                  </svg>
                </span>
              </div>
              <span @click="realMarks++" class="more-store">Ещё один +</span>
            </div>
            <!-- Комментарий -->
            <label class="mb-4">
              Дополнительные детали<br>
              <textarea v-model="comment" @blur="blur($event, 'comment')" :class="getClassInput('comment')"
                        name="comment" placeholder=""></textarea>
              <span class="form__error-message" v-if="v$.comment.$error">Заполните поле</span>
            </label>
          </div>
        </div>

        <!-- -->
        <div class="button-add">
          <button type="submit" class="base-button">Отправить</button>
        </div>

      </form>
    </div>
    <svg class="hidden" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">
      <symbol id="custom-document-upload" viewBox="0 0 24 24">
        <path opacity="0.4" d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z" fill="currentcolor"></path>
        <path d="M15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999Z" fill="currentcolor"></path>
      </symbol>
      <symbol id="custom-video-play" viewBox="0 0 24 24">
        <path d="M14.7295 2H9.26953V6.36H14.7295V2Z" fill="currentcolor"></path>
        <path d="M16.2305 2V6.36H21.8705C21.3605 3.61 19.3305 2.01 16.2305 2Z" fill="currentcolor"></path>
        <path d="M2 7.85938V16.1894C2 19.8294 4.17 21.9994 7.81 21.9994H16.19C19.83 21.9994 22 19.8294 22 16.1894V7.85938H2ZM14.44 16.1794L12.36 17.3794C11.92 17.6294 11.49 17.7594 11.09 17.7594C10.79 17.7594 10.52 17.6894 10.27 17.5494C9.69 17.2194 9.37 16.5394 9.37 15.6594V13.2594C9.37 12.3794 9.69 11.6994 10.27 11.3694C10.85 11.0294 11.59 11.0894 12.36 11.5394L14.44 12.7394C15.21 13.1794 15.63 13.7994 15.63 14.4694C15.63 15.1394 15.2 15.7294 14.44 16.1794Z" fill="currentcolor"></path>
        <path d="M7.76891 2C4.66891 2.01 2.63891 3.61 2.12891 6.36H7.76891V2Z" fill="currentcolor"></path>
      </symbol>
      <symbol id="photo-icon-svg" viewBox="0 0 471.04 471.04">
        <path d="M414.72 112.64h-49.152l-27.136-40.96c-10.24-15.36-28.16-24.576-46.592-24.576H179.2c-18.432 0-36.352 9.216-46.592 24.576l-27.136 40.96H56.32A56.158 56.158 0 0 0 0 168.96v198.656a56.158 56.158 0 0 0 56.32 56.32h358.4a56.158 56.158 0 0 0 56.32-56.32V168.96a56.158 56.158 0 0 0-56.32-56.32zm-179.2 265.216c-70.144 0-126.976-56.832-126.976-126.976s56.832-126.464 126.976-126.464 126.976 56.832 126.976 126.976c0 69.632-56.832 126.464-126.976 126.464zM407.552 192h-22.528c-9.216-.512-16.384-8.192-15.872-17.408.512-8.704 7.168-15.36 15.872-15.872h20.48c9.216-.512 16.896 6.656 17.408 15.872.512 9.216-6.144 16.896-15.36 17.408z" fill="#a1baba" opacity="1" data-original="#000000" class=""/>
        <path d="M235.52 180.736c-38.912 0-70.656 31.744-70.656 70.656s31.744 70.144 70.656 70.144 70.656-31.744 70.656-70.656c0-38.912-31.744-70.144-70.656-70.144z" fill="#a1baba" opacity="1" data-original="#000000" class=""/>
      </symbol>
    </svg>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { mapState } from 'vuex'
import authHelper from '@/classes/formHelper'

export default {
  name: 'OrderNewDelivery',
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  components: {

  },
  data () {
    return {
      loading: false,
      url: '/ordersAddDelivery',
      realMarks: 1,
      point: '',
      comment: '',
    }
  },
  validations: {
    point: { required },
    comment: { required },
  },
  computed: {
    ...mapState(['ordersStore']),
    marksCount() {
      return Array.from({ length: this.realMarks }, (v, k) => k);
    },
  },
  methods: {
    blur: authHelper.blur,
    getClassInput: authHelper.getClassInput,
    submit: authHelper.submit,
    async preSubmit($event, call) {
      this.$store.commit('setLoading', true)
      await this.submit($event, call)
      this.$store.commit('setLoading', false)
    },
    redirectToDetail(data) {
      window.location.href = '/orders/' + data.id
    },
    changeFileValue(event) {
      let label = event.target.nextElementSibling
      label.innerText = event.target.files[0].name
      label.style.opacity = 1
    },
  },
}
</script>

<style lang="scss">
.button-add {
  width: 760px;
  display: flex;
  justify-content: flex-end
}

.more-store {
  border-bottom: 1px dashed;
  display: inline;
  margin: -14px 0 10px 0;
  align-self: flex-start;
  font-size: 13px;
  cursor: pointer;
  color: #006666;

  &:hover {
    color: #005252;
  }
}

.remove {
  position: absolute;
  right: 6px;
  top: 13px;
  width: 16px;
  height: 17px;
  background: #a1baba;
  text-align: center;
  border-radius: 5px;
  line-height: 15px;
  font-size: 13px;
  color: white;
  cursor: pointer;
}

div.form-block__info {
  width: 100%;
  max-width: 392px;
  padding-right: 50px;
}

@media (max-width: 820px) {
  .button-add {
    width: auto;
  }

  h1 i,
  h1 .h1__current {
    display: none;
  }
}
</style>
