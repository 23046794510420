import axios from 'axios'

export default {
  namespaced: true,
  state: {
    list: [],
    typeList: {},
    reasonList: {},
    statusList: {},
    whereList: {},
    whoInitiatedList: {},
    fullDate: null,
  },
  getters: {
    dateObject (state) {
      return {
        dateStart: state.fullDate.split('—')[0].trim(),
        dateEnd: state.fullDate.split('—')[1].trim(),
      }
    },
  },
  actions: {
    async getData (context) {
      const response = await axios.get('returns', context.getters.dateObject)
      context.commit('setData', response.data)
    },
  },
  mutations: {
    setDate (state, data) {
      state.fullDate = data
    },
    setData (state, data) {
      if (data.hasOwnProperty('list')) {
        state.list = data.list
      }
      if (data.hasOwnProperty('typeList')) {
        state.typeList = data.typeList
      }
      if (data.hasOwnProperty('reasonList')) {
        state.reasonList = data.reasonList
      }
      if (data.hasOwnProperty('statusList')) {
        state.statusList = data.statusList
      }
      if (data.hasOwnProperty('whereList')) {
        state.whereList = data.whereList
      }
      if (data.hasOwnProperty('whoInitiatedList')) {
        state.whoInitiatedList = data.whoInitiatedList
      }
    },
  },
}
