<template>
  <div class="flex flex-col items-start">
    <h2 class="mb-4 text-2xl">Профиль</h2>
    <form @submit.prevent="submit" class="form" autocomplete="off">
      <div class="form-block">
        <div class="form-block__info">
          Основное<br>
          <span class="form-block__desc">
          Эта информация не отображается публично.
        </span>
        </div>
        <div class="form-block__fields">
          <label class="mb-4">
            Фамилия<br>
            <input v-model="lastName" :class="getClassInput('lastName')" @blur="blur($event, 'lastName')"
                   name="lastName" type="text" class="form__input">
            <span class="form__error-message" v-if="v$.lastName.$error">Обязательно для заполнени</span>
          </label>
          <label class="mb-4">
            Имя<br>
            <input v-model="name" :class="getClassInput('name')" @blur="blur($event, 'name')"
                   name="name" type="text" class="form__input">
            <span class="form__error-message" v-if="v$.name.$error">Обязательно для заполнени</span>
          </label>
          <label class="mb-4">
            Отчество<br>
            <input v-model="secondName" name="secondName" type="text" class="form__input">
          </label>
        </div>
      </div>
      <div class="form-block">
        <div class="form-block__info">
          Контакты<br>
          <span class="form-block__desc">
          Эта информация не отображается публично.
        </span>
        </div>
        <div class="form-block__fields">
          <label class="mb-4">
            Телефон
            <input v-model="localPhone" name="personalPhone" v-maska data-maska="+7 (###) ###-##-##" type="text"
                   class="form__input">
          </label>
          <label class="mb-4">
            E-mail
            <input v-model="email" type="text" class="form__input" disabled>
          </label>
        </div>
      </div>
      <div class="form-block">
        <div class="form-block__info">
          Пароль<br>
          <span class="form-block__desc">
          Заполняется только, если необходимо обновить пароль.
        </span>
        </div>
        <div class="form-block__fields">
          <label class="mb-4">
            Новый пароль
            <input v-model="password" :class="getClassInput('password')" @blur="blur($event, 'password')"
                   autoComplete="new-password" name="password" type="password" class="form__input">
            <span class="form__error-message" v-if="v$.password.$error">Пароль должен быть не менее 6 символов</span>
          </label>
          <label class="mb-4">
            Повторите пароль
            <input v-model="confirmPassword" :class="getClassInput('confirmPassword')"
                   @blur="blur($event, 'confirmPassword')"
                   name="confirmPassword" type="password" class="form__input">
            <span class="form__error-message" v-if="v$.confirmPassword.$error">Пароли не совпадают</span>
          </label>
        </div>
      </div>
      <div class="button-add">
        <button type="submit" class="base-button">Сохранить</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { email, minLength, required, sameAs } from '@vuelidate/validators'
import authHelper from '@/classes/formHelper'

export default {
  name: 'ProfileEdit',
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  data () {
    return {
      localPhone: null,
      password: null,
      confirmPassword: null,
      url: '/user/update',
    }
  },
  validations () {
    return {
      name: { required },
      lastName: { required },
      email: { required, email, $autoDirty: false },
      password: { minLength: minLength(6) },
      confirmPassword: { minLength: minLength(6), sameAsPassword: sameAs(this.password) },
    }
  },
  computed: {
    ...mapState(['user']),
    name: {
      get () {
        return this.user.name
      },
      set (value) {
        this.$store.commit('changeName', value)
      },
    },
    lastName: {
      get () {
        return this.user.lastName
      },
      set (value) {
        this.$store.commit('changeLastName', value)
      },
    },
    secondName: {
      get () {
        return this.user.secondName
      },
      set (value) {
        this.$store.commit('changeSecondName', value)
      },
    },
    personalWww: {
      get () {
        return this.user.personalWww
      },
      set (value) {
        this.$store.commit('changePersonalWww', value)
      },
    },
    email: {
      get () {
        return this.user.email
      },
      set (value) {
        this.$store.commit('changeEmail', value)
      },
    },
  },
  watch: {
    localPhone (value) {
      if (value === '8') {
        this.localPhone = '+7 ('
      }
      else if (value === '+7 (8') {
        this.localPhone = '+7 (9'
      }
    },
  },
  methods: {
    blur: authHelper.blur,
    getClassInput: authHelper.getClassInput,
    submit: authHelper.submit,
  },
  mounted () {
    this.localPhone = this.user.personalPhone
  },
}
</script>

<style scoped>
.button-add {
  width: 760px;
  display: flex;
  justify-content: flex-end
}

@media (max-width: 820px) {
  .button-add {
    width: auto;
  }
}
</style>
