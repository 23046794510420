<template>
  <h1>Восстановление пароля</h1>
  <form @submit.prevent="submit" class="form auth-form">
    <div v-if="successMessage" class="alert alert--success mb-4">
      {{ successMessage }}
    </div>
    <span class="text-sm mb-4 opacity-70">
      Ссылка для смены пароля, а также ваши регистрационные данные,
      будут высланы на вашу электронную почту.
    </span>
    <label class="mb-4">
      E-mail<br>
      <input v-model="email" @blur="blur($event, 'email')" :class="getClassInput('email')" type="text" name="email">
      <span class="form__error-message" v-if="v$.email.$error">E-mail адрес указан неверно</span>
    </label>
    <button type="submit" class="base-button">Восстановить</button>
    <div class="text-center mt-2">
      <a @click="$emit('change-page', 'SignIn')" class="dashed">&larr;&nbsp;Авторизация</a>
    </div>
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import authHelper from '@/classes/formHelper'

export default {
  name: 'ForgotPassword',
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  data () {
    return {
      email: '',
      successMessage: null,
      url: '/user/codeGenerate',
    }
  },
  validations () {
    return {
      email: { required, $autoDirty: false },
    }
  },
  methods: {
    blur: authHelper.blur,
    getClassInput: authHelper.getClassInput,
    submit: authHelper.submit,
  },
}
</script>
