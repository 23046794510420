export default {
  // Форматирование валюты
  toCurrency (n, curr, LanguageFormat = undefined) {
    return Intl.NumberFormat(LanguageFormat,
      { style: 'currency', currency: curr }).format(n)
  },
  // Возвращает белый или черный цвет, контрастный к фону
  getTextColor (hexColor) {
    // Преобразуем hex-код в значения RGB
    const r = parseInt(hexColor.substr(1, 2), 16) / 255
    const g = parseInt(hexColor.substr(3, 2), 16) / 255
    const b = parseInt(hexColor.substr(5, 2), 16) / 255

    // Вычисляем относительную яркость
    const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b

    // Определяем цвет текста на основе яркости
    return brightness > 0.5 ? '#000000' : '#FFFFFF'
  },
  // Делает цвет в том же тоне темнее на % percent
  darkenHexColor (hexColor, percent) {
    // Преобразуем hex в RGB
    let r = parseInt(hexColor.substr(1, 2), 16)
    let g = parseInt(hexColor.substr(3, 2), 16)
    let b = parseInt(hexColor.substr(5, 2), 16)

    // Преобразуем RGB в HSL
    r /= 255, g /= 255, b /= 255
    const max = Math.max(r, g, b), min = Math.min(r, g, b)
    let h, s, l = (max + min) / 2

    if (max === min) {
      h = s = 0 // achromatic
    }
    else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break
        case g:
          h = (b - r) / d + 2
          break
        case b:
          h = (r - g) / d + 4
          break
      }
      h /= 6
    }

    // Уменьшаем светлоту
    l = l - (l * percent / 100)
    l = Math.max(0, Math.min(l, 1)) // Убедимся, что l находится в пределах [0, 1]

    // Конвертируем HSL обратно в HEX
    function hslToHex (h, s, l) {
      let r, g, b
      if (s === 0) {
        r = g = b = l // achromatic
      }
      else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) {
            t += 1
          }
          if (t > 1) {
            t -= 1
          }
          if (t < 1 / 6) {
            return p + (q - p) * 6 * t
          }
          if (t < 1 / 2) {
            return q
          }
          if (t < 2 / 3) {
            return p + (q - p) * (2 / 3 - t) * 6
          }
          return p
        }
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        r = hue2rgb(p, q, h + 1 / 3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1 / 3)
      }
      const toHex = x => {
        const hex = Math.round(x * 255).toString(16)
        return hex.length === 1 ? '0' + hex : hex;
      };
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }

    return hslToHex(h, s, l);
  },
}
