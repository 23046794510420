<template>
  <div>
    <h1 class="flex items-center invisible md:visible">
      Заявки
      <button @click="$router.push('orders/new')" type="submit" class="order-add-btn base-button hidden">+ Новая заявка</button>
    </h1>
    <div class="flex justify-end filter-wrap">
      <Multiselect v-model="filterStatus" :options="ordersStore.statusList" placeholder="-- Статус заказа --"
                   class="w-64 mr-4 mx-0 filter-wrap__item"/>
    </div>
    <div class="inside overflow-x-auto">
      <table-component :list="list" type="order">
        <tr>
          <th class="w-20">ID</th>
          <th class="w-48">Дата</th>
          <th>Название</th>
<!--          <th>Тип</th>-->
          <th class="w-96">Статус</th>
        </tr>
      </table-component>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

import TableComponent from '@/components/table/TableComponent'

export default {
  name: 'OrdersPage',
  components: {
    TableComponent,
    Multiselect,
  },
  data () {
    return {
      filterStatus: null,
      loading: false,
    }
  },
  computed: {
    ...mapState(['dateConfigBetween', 'ordersStore']),
    list () {
      return this.ordersStore.list.filter(item => this.filterStatus === item.status || !this.filterStatus)
    },
    date: {
      get () {
        return this.ordersStore.fullDate
      },
      set (value) {
        this.$store.commit('ordersStore/setDate', value)
      }
    },
  },
  watch: {

  },
  methods: {

  },
  mounted () {
    let date = new Date(),
        firstDay = new Date(date.getFullYear(), date.getMonth(), 1),
        lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    let year = firstDay.getFullYear(),
        month = firstDay.getMonth() + 1 < 10 ? '0' + (firstDay.getMonth() + 1) : firstDay.getMonth() + 1,
        day = '0' + firstDay.getDate()

    this.date = year + '-' + month + '-' + day + ' — ' +
        year + '-' + month + '-' + lastDay.getDate()
  },
}
</script>

<style lang="scss">
.order-add-btn {
  font-family: Roboto, Helvetica neue, Helvetica, sans-seriff, serif;
  margin-left: 16px;
}
</style>
