<template>
  <h1>Авторизация</h1>
  <form @submit.prevent="submit($event, reloadAfterSuccessAuth)" method="post" class="form auth-form">
    <label class="mb-4">
      E-mail<br>
      <input v-model="email" @blur="blur($event, 'email')" type="text" name="email"
             :class="getClassInput('email')">
      <span class="form__error-message" v-if="v$.email.$error">E-mail адрес указан неверно</span>
    </label>
    <label class="mb-4">
      Пароль<br>
      <input v-model="password" @blur="blur($event, 'password')" type="password" name="password"
             :class="getClassInput('password')">
      <span class="form__error-message" v-if="v$.password.$error">Укажите пароль</span>
    </label>
    <div class="flex justify-between mb-4 select-none">
      <label class="flex">
        <input v-model="remember" type="checkbox" name="remember" class="mr-1">
        Запомнить меня
      </label>
      <a @click="$emit('change-page', 'ForgotPassword')" class="dashed gray">Забыли пароль?</a>
    </div>
    <button type="submit" class="base-button">Войти</button>
    <div class="text-center mt-2">
      <a @click="$emit('change-page', 'SignUp')" class="dashed">Регистрация</a>
    </div>
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import authHelper from '@/classes/formHelper'
import axios from 'axios'

export default {
  name: 'SignIn',
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  data () {
    return {
      email: '',
      password: '',
      remember: true,
      url: '/user/signIn',
      confirmUserId: null,
      confirmCode: null,
      disableSuccess: true
    }
  },
  validations () {
    return {
      email: { required, $autoDirty: false },
      password: { required },
    }
  },
  methods: {
    blur: authHelper.blur,
    getClassInput: authHelper.getClassInput,
    submit: authHelper.submit,
    reloadAfterSuccessAuth(data) {
      window.location.reload()
    },
    async signUpConfirm() {
      const urlParams = new URLSearchParams(window.location.search)
      this.confirmUserId = urlParams.get('userId')
      this.confirmCode = urlParams.get('confirmCode')
      if (this.confirmUserId !== null && this.confirmCode !== null) {
        let formData = new FormData()
        formData.append('userId', this.confirmUserId)
        formData.append('confirmCode', this.confirmCode)
        let response = await axios({
          url: '/user/signUpConfirm',
          method: 'post',
          data: formData
        })
        let data = response.data
        if (data.success === true && !data.errorMessage) {
          this.errorMessage = null
          this.successMessage = data.successMessage
        } else if (data.errorMessage) {
          this.errorMessage = data.errorMessage
        }
      }
    }
  },
  mounted () {
    this.signUpConfirm()
  },
}
</script>
