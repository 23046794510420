<template>
  <div class="auth-wrap flex">
    <div class="flex items-center justify-center text-center auth-left-block">
      <div>
        <h2 style="font-weight: bold; font-size: 30px; margin-top: 32px">Личный кабинет</h2>
        <img src="/assets/BT_Logo_ partner.svg" class="logo" alt="">
      </div>
    </div>
    <div class="flex items-center md:justify-center md:mt-0 mt-6 auth-right-block">
      <img src="/assets/BT_Logo_ partner.svg" class="logo logo--right" alt="">
      <div class="form-wrapper">
        <component @changePage="setPage" v-bind:is="currentComponent"/>
      </div>
    </div>
  </div>
</template>

<script>
import SignIn from '@/components/auth/SignIn'
import SignUp from '@/components/auth/SignUp'
import ForgotPassword from '@/components/auth/ForgotPassword'
import ChangePassword from '@/components/auth/ChangePassword'

export default {
  name: 'AuthBlock',
  components: { SignIn, SignUp, ForgotPassword, ChangePassword },
  data () {
    return {
      currentComponent: 'SignIn',
    }
  },
  methods: {
    setPage (value) {
      this.currentComponent = value
    },
  },
  mounted () {
    if (window.location.href.indexOf('USER_CHECKWORD') >= 0) {
      this.currentComponent = 'ChangePassword'
    }
  },
}
</script>

<style lang="scss">
.form-wrapper {
  width: 100%;
  max-width: 380px;
}

.auth-left-block {
  background-color: #e0f2f2;
  text-align: center;
  color: black;
  width: 50%;
}

.auth-right-block {
  width: 50%;
}

.auth-wrap {
  min-height: 100vh;
}

.auth-form {
  max-width: 380px;
}

.logo {
  max-width: 400px;
  margin: 50px auto;
  width: 100%;

  &--right {
    display: none;
  }
}

@media (max-width: 860px) {
  div.auth-left-block {
    display: none;
  }

  .auth-wrap {
    justify-content: center;
  }

  .auth-right-block {
    flex-direction: column;
    width: 100%;
  }

  .logo--right {
    display: block;
    margin: 0px auto;
  }
}

@media (max-width: 400px) {
  .form-wrapper {
    padding: 20px;
  }
}
</style>
