import axios from 'axios'

export default {
  namespaced: true,
  state: {
    list: [],
    statusList: {},
    addServices: [],
    pointList: [],
    fullDate: null,
  },
  getters: {

  },
  actions: {
    async getData (context) {
      const response = await axios.get('orders')
      context.commit('setData', response.data)
    },
  },
  mutations: {
    setDate (state, data) {
      state.fullDate = data
    },
    setData (state, data) {
      if (data.hasOwnProperty('list')) {
        state.list = data.list
      }
      if (data.hasOwnProperty('statusList')) {
        state.statusList = data.statusList
      }
      if (data.hasOwnProperty('addServices')) {
        state.addServices = data.addServices
      }
      if (data.hasOwnProperty('pointList')) {
        state.pointList = data.pointList
      }
    },
  },
}
