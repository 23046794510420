import axios from 'axios'

export default {
  namespaced: true,
  state: {
    editable: 'list',
    statusDefault: '0',
    statuses: [],
    list: [],
    types: [],
  },
  getters: {
    editableBrand(state) {
      return state.list.find(item => item.id === state.editable)
    }
  },
  actions: {
    async getData (context) {
      const response = await axios.get('/brands')
      await context.commit('setData', response.data)
      return response
    },
  },
  mutations: {
    setData (state, data) {
      if (data.hasOwnProperty('list')) {
        state.list = data.list
      }
      if (data.hasOwnProperty('statusDefault')) {
        state.statusDefault = data.statusDefault.toString()
      }
      if (data.hasOwnProperty('statuses')) {
        state.statuses = data.statuses
      }
      if (data.hasOwnProperty('types')) {
        state.types = data.types
      }
    },
    addItem(state, data) {
      state.list.unshift(data)
    },
    setEditable(state, data) {
      state.editable = data
    }
  },
}
