<template>
  <div>
    <h1 class="flex items-center">
      Главная
    </h1>
    <div class="inside text-center">
      <h1 class="text-xl md:text-3xl" style="margin-top: 0; margin-left: 10px">
        Оформить заявку на:
      </h1>
      <button @click="$router.push('orders/newDelivery')" type="submit" class="order-add-btn base-button mb-3">
        Забор/доставку груза
      </button>

      <button @click="$router.push('orders/new')" type="submit" class="order-add-btn base-button">
        Обработку товара
      </button>

      <br><br><br>
    </div>

  </div>
</template>

<script>

export default {
  name: 'PageMain',
  components: {

  },
  data () {
    return {

    }
  },
  watch: {

  },
  computed: {

  },
  methods: {

  },
  mounted () {

  }
}
</script>
