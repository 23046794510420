import axios from 'axios'

export default {
  async submit (e, call) {
    if (this.disabledSubmit && this.disabledSubmit === true) {
      return
    }

    const result = await this.v$.$validate()
    if (!result) {
      if (this.warningMessage) {
        this.$toast.warning(this.warningMessage, { duration: 8000 })
      }
      return
    }

    let bodyFormData = new FormData(e.target),
      response = await axios({
        url: this.url,
        method: 'post',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

    let data = response.data

    if (data.success === true && !data.errorMessage) {
      if (this.disableSuccess !== true) {
        if (this.successMessage !== undefined) {
          this.successMessage = data.successMessage
        }
        else {
          this.$toast.success(data.successMessage)
        }
      }
      if (this.redirectAfterSuccess) {
        setTimeout(() => {
          window.location.href = this.redirectAfterSuccess
        }, 4000)
      }
      if (isFunction(call)) {
        call(data)
      }
    }
    else if (data.errorMessage) {
      this.$toast.error(data.errorMessage, { duration: 8000 })
    }
    else {
      this.$toast.show('Unknown error 🤷‍')
    }
  },
  blur (event, name) {
    if (event.target.value) {
      this.v$[name].$touch()
    }
    else {
      this.v$[name].$reset()
    }
  },
  getClassInput (name) {
    return {
      'form__input': true,
      'form__input--error': !!(this.v$.hasOwnProperty(name) &&
        this.v$[name].$errors.length),
    }
  },
}

let isFunction = function (functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) ===
    '[object Function]'
}
