// Routing
import PageMain from '@/pages/PageMain'
import OrdersPage from '@/pages/OrdersPage'
import OrderDetail from '@/pages/OrderDetail'
import OrderNew from '@/pages/OrderNew'
import OrderNewDelivery from '@/pages/OrderNewDelivery'
import SettingsPage from '@/pages/SettingsPage'
import NotFound from '@/pages/NotFound'
import { createRouter, createWebHistory } from 'vue-router'

// Vue
import { createApp } from 'vue'

// App and Store
import App from '@/App'
import store from '@/store'
import axios from 'axios';

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://lk.lite-box.mac/api/seller' : '/api/seller'

// Styles
import '@/scss/_main.scss'

// Init app
async function getAuth() {
  let user = await axios('/user')
  return user.data
}

import { vMaska } from "maska"
import Toaster from "@meforma/vue-toaster";

getAuth().then(user => {
  store.commit('setUser', user)
  let routes = [
    {
      path: '/',
      name: 'PageMain',
      component: PageMain,
      meta: {title: 'Главная', mainMenu: true },
    },

    {
      path: '/orders',
      name: 'OrdersPage',
      component: OrdersPage,
      meta: {title: 'Ваши заявки', mainMenu: true },
    },
    {
      path: '/orders/:orderId',
      name: 'OrderDetail',
      component: OrderDetail,
      meta: {title: 'Order Detail' },
    },
    {
      path: '/orders/new',
      name: 'OrderNew',
      component: OrderNew,
      meta: {title: 'Order Detail' },
    },
    {
      path: '/orders/newDelivery',
      name: 'OrderNewDelivery',
      component: OrderNewDelivery,
      meta: {title: 'Order Detail' },
    },

    {
      path: '/settings',
      name: 'SettingsPage',
      component: SettingsPage,
      meta: {title: 'Настройки профиля' },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFound,
      meta: {title: '404' },
    },
  ]

  const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'nav__item--active',
    linkExactActiveClass: '',
  })

  createApp(App)
  .directive("maska", vMaska)
  .use(Toaster)
  .use(store)
  .use(router)
  .mount('#app-seller')
})
