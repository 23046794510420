<template>
  <tr @click="$router.push('/returns/' + row.id)" class="cursor-pointer">
    <td>{{ row.id }}</td>
    <td>
      <div class="table-inside__date">
        {{ getDate(row) }}
      </div>
    </td>
    <td>{{ row.name }}</td>
    <td><span :class="statusClasses(row, 'status')">{{ statusTitle(row) }}</span></td>
    <td><span :class="statusClasses(row, 'where')">{{ whereTitle(row) }}</span></td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'
import Helper from '@/classes/helper'

export default {
  name: 'ReturnRow',
  props: {
    row: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapState(['returnsStore'])
  },
  methods: {
    statusClasses(row, type) {
      return [
        'table-inside__status',
        'table-inside__status-' + row[type]
      ]
    },
    statusTitle(row) {
      return this.returnsStore.statusList[row.status]
    },
    whereTitle(row) {
      return this.returnsStore.whereList[row.where]
    },
    getDate(row) {
      return row.date.split(' ')[0]
    },
    getDateTime(row) {
      return row.date.split(' ')[1]
    },
    toCurrency: Helper.toCurrency,
  }
}
</script>
