<template>
  <div class="flex flex-col items-start">
    <h2 class="mb-4 text-2xl flex items-start">
      <template v-if="component === 'list'">
        <span>Бренды</span>
        <span class="link h-7 ml-3" @click="setComponent('add')">+ добавить бренд</span>
      </template>
      <template v-else>
        <span @click="setComponent('list')" class="h1__title">Бренды</span>
        <i>&rarr;</i>
        <span v-if="editableBrand === undefined" class="h1__current">Добавить</span>
        <span v-else class="h1__current">{{ editableBrand.name }}</span>
      </template>
    </h2>

    <div v-if="component === 'list'" class="alert alert--info">
      Здесь можно загрузить документы, которые подтвердят, что вы официально торгуете брендированными товарами.
    </div>
    <br><br>

    <div class="w-full">
      <table-component v-if="component === 'list'" :list="list" type="brand" class="table-inside--no-ng">
        <tr>
          <th class="w-64">Название</th>
          <th class="w-32">Выдан</th>
          <th class="w-32">Истекает</th>
          <th class="w-64">Тип</th>
          <th class="w-44">Статус</th>
          <th class="w-32">Загружен</th>
        </tr>
      </table-component>
      <brand-edit v-else @set-component="setComponent"></brand-edit>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import TableComponent from '@/components/table/TableComponent'
import BrandEdit from '@/components/settings/BrandEdit'

export default {
  name: 'BrandsList',
  components: { TableComponent, BrandEdit },
  computed: {
    ...mapState('brandsStore', ['list', 'editable']),
    ...mapGetters('brandsStore', ['editableBrand']),
    component () {
      return this.editable === 'list' ? 'list' : null
    },
  },
  methods: {
    setComponent (value) {
      this.$store.commit('brandsStore/setEditable', value)
    },
  },
  mounted () {
    this.$store.dispatch('brandsStore/getData')
  },
  unmounted () {
    this.setComponent('list')
  },
}
</script>
