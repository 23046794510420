import { createStore } from 'vuex'
import ordersStore from '@/store/modules/ordersStore'
import returnsStore from '@/store/modules/returnsStore'
import pickupStore from '@/store/modules/pickupStore'
import requisitesStore from '@/store/modules/requisitesStore'
import brandsStore from '@/store/modules/brandsStore'
import { Russian } from 'flatpickr/dist/l10n/ru'

export default createStore({
  modules: {
    ordersStore,
    returnsStore,
    pickupStore,
    requisitesStore,
    brandsStore,
  },
  state () {
    return {
      loading: false,
      user: null,
      shopOnly: true,
      dateConfigBetween: {
        wrap: true,
        altFormat: 'd.m.Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        mode: 'range',
        'locale': Russian,
        altInputClass: 'dateInputDb filter-wrap__item',
      },
      paginationConfig: {
        itemsPerPage: 50, // элементов на стр.
        rangeSize: 1, // стр. вокруг текущей стр.
        hideFirstButton: true,
        hideLastButton: true,
      },
    }
  },
  getters: {},
  actions: {},
  mutations: {
    setLoading (state, data) {
      state.loading = data
    },
    setUser (state, data) {
      state.user = data
    },
    setShopOnly (state, data) {
      state.shopOnly = data
    },
    changeName(state, data) {
      state.user.name = data
    },
    changeLastName(state, data) {
      state.user.lastName = data
    },
    changeSecondName(state, data) {
      state.user.secondName = data
    },
    changeEmail(state, data) {
      state.user.email = data
    },
    changePhone(state, data) {
      if (data === '+7 (8') {
        data = '+7'
      }
      state.user.personalPhone = data
    }
  },
})
