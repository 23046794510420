<template>
  <tr @click="setEditableId(row.id)" class="cursor-pointer">
    <td><div class="truncate w-64">{{ row.name }}</div></td>
    <td>{{ row.issued }}</td>
    <td>{{ row.expires }}</td>
    <td><div class="truncate w-64">{{ typeTitle(row) }}</div></td>
    <td>{{ statusTitle(row) }}</td>
    <td>{{ row.dateCreate }}</td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BrandRow',
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['brandsStore']),
  },
  methods: {
    statusTitle(row) {
      return this.brandsStore.statuses[row.status]
    },
    typeTitle(row) {
      return this.brandsStore.types[row.typeBrand]
    },
    setEditableId(id) {
      this.$store.commit('brandsStore/setEditable', id)
    }
  },
}
</script>
