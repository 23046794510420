<template>
  <tr @click="$router.push('/orders/' + row.id)" class="cursor-pointer">
    <td>{{ row.id }}</td>
    <td>
      <div class="table-inside__date">
        {{ getDate(row) }}
        <span>{{ getDateTime(row) }}</span>
      </div>
    </td>
    <td>{{ row.title }}</td>
<!--    <td>
      {{ row.comment ? 'Логистика' : 'Обраотка товаров' }}
    </td>-->
    <td><span :style="statusStyle(row)" :class="statusClasses(row)">{{ statusTitle(row) }}</span></td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'
import Helper from '@/classes/helper'

export default {
  name: 'OrderRow',
  props: {
    row: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapState(['ordersStore'])
  },
  methods: {
    statusClasses(row) {
      return [
          'whitespace-nowrap',
          'table-inside__status',
          'table-inside__status-' + row.status
      ]
    },
    statusTitle(row) {
      return this.ordersStore.statusList[row.status]['name']
    },
    statusStyle(row) {
      let backgroundColor = this.ordersStore.statusList[row.status]['color'],
          color = this.getTextColor(backgroundColor),
          border = '1px solid ' + this.darkenHexColor(backgroundColor, 10)
      return {
        backgroundColor,
        color,
        border
      }
    },
    getTextColor: Helper.getTextColor,
    darkenHexColor: Helper.darkenHexColor,
    getDate(row) {
      return row.date.split(' ')[0]
    },
    getDateTime(row) {
      return row.date.split(' ')[1]
    },
    toCurrency: Helper.toCurrency,
  }
}
</script>
