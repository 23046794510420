<template>
  <h1>
    <span @click="$router.push(listPageHref)" class="h1__title">Заявки</span>
    <i>&rarr;</i>
    <span class="h1__current">№ {{ orderId }}</span>
  </h1>
  <div class="flex gap-4">
    <div class="w-full">
      <div class="inside">
        <table class="table-inside table-inside--no-hover">
          <!-- HEAD -->
          <thead>
          <tr class="">
            <th>Детали заявки</th>
          </tr>
          </thead>
        </table>
        <div class="">
          <form @submit.prevent="preSubmit($event, redirectToDetail)" class="form" autocomplete="off" enctype="multipart/form-data">
            <input type="hidden" name="dealId" :value="orderId">
            <div v-if="order.photo && order.photo.length || (order.video && order.video.length) || (order.files && order.files.length)" class="form-block">
              <div class="form-block__info">
                Документы. Фото. Видео<br>
                <span class="form-block__desc">
                  В этом разделе отображаются файлы загружаемые вашим менеджером.<br><br>
                  Для загрузки стикеров «Честный знак» И распределения по коробам дождитесь информации от вашего менеджера.
                </span>
              </div>
              <div class="form-block__fields">
                <!-- Slider main container -->
                <div v-if="order.photo && order.photo.length" class="swiper mb-6" ref="swiper" style="max-width: 450px; margin: 0 0 30px 0">
                  <!-- Additional required wrapper -->
                  <div class="swiper-wrapper">
                    <!-- Slides -->
                    <a :href="'https://lk.lite-box.ru' + photo.full" target="_blank" v-for="(photo, i) in order.photo" class="swiper-slide" style="display: inline-block; width: 120px" :key="i">
                      <img style="width: 120px" alt=""
                           :src="getPhotoSrc(photo)" :key="i">
                    </a>
                  </div>
                  <!-- If we need pagination -->
                  <div class="swiper-pagination"></div>

                  <!-- If we need scrollbar -->
                  <div class="swiper-scrollbar"></div>
                </div>

                <div v-if="order.video && order.video.length" class="mb-4">
                  <b>Видео</b><br>
                  <ul>
                    <li v-for="(item, i) in order.video" :key="i">
                      <a :href="'https://lk.lite-box.ru' + item.path" target="_blank" class="flex">
                        <svg class="pc-icon">
                          <use xlink:href="#custom-video-play"></use>
                        </svg>
                        {{ item.name }}
                      </a>
                    </li>
                  </ul>
                </div>

                <div v-if="order.files && order.files.length" class="mb-4">
                  <b>Документы</b><br>
                  <ul>
                    <li v-for="(item, i) in order.files" :key="i">
                      <a :href="'https://lk.lite-box.ru' + item.path" target="_blank" class="flex">
                        <svg class="pc-icon">
                          <use xlink:href="#custom-document-upload"></use>
                        </svg>
                        {{ item.name }}
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

            <div v-if="productList && productList.length" class="form-block">
              <div class="form-block__fields" style="width: 100%">
                <table class="table-prod mb-4">
                  <thead>
                  <tr>
                    <th>Фото</th>
                    <th><div class="w-40">Название</div></th>
                    <th><div class="w-24">Артикул</div></th>
                    <th><div class="w-24">Размер</div></th>
                    <th><div class="w-24">Баркод МП </div></th>
                    <th><div class="w-20">Кол-во</div></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(product, index) in productList" :key="index">
                    <td><img v-if="product.img" :src="product.img" alt=""></td>
                    <td>{{ product.name }}</td>
                    <td>{{ product.art }}</td>
                    <td>{{ product.size }}</td>
                    <td>{{ product.barcode }}</td>
                    <td>{{ product.amount }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="form-block">
              <div class="form-block__info hidden md:block">
                Основное<br>
                <span class="form-block__desc">

                </span>
              </div>
              <div class="form-block__fields">
                <div v-if="order.howPack" class="mb-4">
                  <b>Как необходимо упаковать?</b><br>
                  {{ order.howPack }}
                </div>

                <!-- Честный знак -->
                <div class="mb-4">
                  <b>Честный знак:</b><br>
                  <template v-for="(item, i) in order.realMark" :key="i">
                    <a :href="'https://lk.lite-box.ru/' + item.path" target="_blank">{{ item.name }}</a><template v-if="order.realMark && (i+1 < order.realMark.length)">, </template>
                  </template>
                  <br>
                  <!-- Честный знак: дополнение -->
                  <div v-for="index in marksCount" :key="index" class="form__input form__input-file">
                    <label style="height: 100%;width: calc(100% - 40px);display: inline-block;">
                      <input @change="changeFileValue" name="realMark[]" class="hidden" type="file">
                      <span class="label">.zip или .pdf</span>
                    </label>
                    <span v-if="(index+1) === realMarks && realMarks > 1" class="remove" @click="realMarks--">x</span>
                    <span v-else class="form__input-icon">
                      <svg class="pc-icon">
                        <use xlink:href="#custom-document-upload"></use>
                      </svg>
                    </span>
                  </div>
                  <span @click="realMarks++" class="more-store">Ещё один +</span>
                </div>

                <!-- Распределение по коробкам -->
                <div class="mb-4">
                  <b>Распределение по коробкам:</b><br>
                  <template v-for="(item, i) in order.boxes" :key="i">
                    <a :href="'https://lk.lite-box.ru/' + item.path" target="_blank">
                      {{ item.name }}
                    </a>
                    <template v-if="order.boxes && (i+1 < order.boxes.length)">, </template>
                  </template>
                  <!-- Распределение по коробкам: дополнение -->
                  <div v-for="index in boxesCount" :key="index" class="form__input form__input-file">
                    <label style="height: 100%;width: calc(100% - 40px);display: inline-block;">
                      <input @change="changeFileValue" name="boxes[]" class="hidden" type="file">
                      <span class="label">.zip или .pdf</span>
                    </label>
                    <span v-if="(index+1) === boxes && boxes > 1" class="remove" @click="boxes--">x</span>
                    <span v-else class="form__input-icon">
                      <svg class="pc-icon">
                        <use xlink:href="#custom-document-upload"></use>
                      </svg>
                    </span>
                  </div>
                  <span @click="boxes++" class="more-store">Ещё один +</span>
                </div>

              </div>
            </div>

            <div v-if="(order.addService && order.addService.length) || order.comment || order.tz" class="form-block">
              <div class="form-block__info hidden md:block">
                Дополнительно<br>
                <span class="form-block__desc">
                  Дополнительные услуги
                </span>
              </div>
              <div class="form-block__fields">
                <div v-if="order.comment" class="mb-4">
                  <b>Дополнительно:</b><br>
                  {{ order.comment }}
                </div>
                <div v-if="order.tz" class="mb-4">
                  <b>Данные для пропуска:</b><br>
                  {{ order.tz }}
                </div>
                <div v-if="order.addService && order.addService.length" class="mb-2">
                  <b class="mb-2">Дополнительные услуги:</b>
                  <div v-for="(item, i) in order.addService" :key="i">
                    {{ i+1 }}. {{ ordersStore.addServices[item] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="button-add">
              <button type="submit" class="base-button">Сохранить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="w-1/3 shrink-0 right-block">
      <div class="inside">
        <h3 class="mb-2 text-lg mb-6">Статус заявки</h3>
        <div class="stepping-board">
          <ul class="stepping-board__list">
            <li v-for="(item, i) in orderStatusList" :class="{'stepping-board__item': true, 'opacity-30': !item.ahead}" :key="i">
              <div class="stepping-board__item-circle stepping-board__item-circle--success">
                <svg v-if="item.ahead" xmlns="http://www.w3.org/2000/svg" width="36px" stroke="#7bc27c" height="36px" viewBox="0 0 36 36">
                  <path class="clr-i-outline clr-i-outline-path-1"
                        d="M13.72,27.69,3.29,17.27a1,1,0,0,1,1.41-1.41l9,9L31.29,7.29a1,1,0,0,1,1.41,1.41Z"/>
                </svg>
              </div>
              <div class="stepping-board__item-content">
                <div class="stepping-board__item-content-square" :style="item.current ? statusStyle(item) : {}">
                  <b v-if="item.current">{{ item.name }}</b>
                  <template v-else>{{ item.name }}</template>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <svg class="hidden" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">
    <symbol id="custom-document-upload" viewBox="0 0 24 24">
      <path opacity="0.4" d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z" fill="currentcolor"></path>
      <path d="M15.7997 2.20999C15.3897 1.79999 14.6797 2.07999 14.6797 2.64999V6.13999C14.6797 7.59999 15.9197 8.80999 17.4297 8.80999C18.3797 8.81999 19.6997 8.81999 20.8297 8.81999C21.3997 8.81999 21.6997 8.14999 21.2997 7.74999C19.8597 6.29999 17.2797 3.68999 15.7997 2.20999Z" fill="currentcolor"></path>
    </symbol>
    <symbol id="custom-video-play" viewBox="0 0 24 24">
      <path d="M14.7295 2H9.26953V6.36H14.7295V2Z" fill="currentcolor"></path>
      <path d="M16.2305 2V6.36H21.8705C21.3605 3.61 19.3305 2.01 16.2305 2Z" fill="currentcolor"></path>
      <path d="M2 7.85938V16.1894C2 19.8294 4.17 21.9994 7.81 21.9994H16.19C19.83 21.9994 22 19.8294 22 16.1894V7.85938H2ZM14.44 16.1794L12.36 17.3794C11.92 17.6294 11.49 17.7594 11.09 17.7594C10.79 17.7594 10.52 17.6894 10.27 17.5494C9.69 17.2194 9.37 16.5394 9.37 15.6594V13.2594C9.37 12.3794 9.69 11.6994 10.27 11.3694C10.85 11.0294 11.59 11.0894 12.36 11.5394L14.44 12.7394C15.21 13.1794 15.63 13.7994 15.63 14.4694C15.63 15.1394 15.2 15.7294 14.44 16.1794Z" fill="currentcolor"></path>
      <path d="M7.76891 2C4.66891 2.01 2.63891 3.61 2.12891 6.36H7.76891V2Z" fill="currentcolor"></path>
    </symbol>
  </svg>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import axios from 'axios'

import { mapState } from 'vuex'
import Helper from '@/classes/helper'

// core version + navigation, pagination modules:
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import authHelper from '@/classes/formHelper'
//
export default {
  name: 'OrderDetail',
  components: {

  },
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  validations () {
    return {
    }
  },
  data () {
    return {
      realMarks: 1,
      boxes: 1,
      loading: false,
      url: '/ordersSave',
      howPack: '',
      point: '',
      productList: []
    }
  },
  watch: {
    order(value) {
      setTimeout(() => {
        this.initSwiper()
      }, 100)
    }
  },
  computed: {
    ...mapState(['ordersStore']),
    marksCount() {
      return Array.from({ length: this.realMarks }, (v, k) => k);
    },
    boxesCount() {
      return Array.from({ length: this.boxes }, (v, k) => k);
    },
    orderId () {
      return Number(this.$route.params.orderId)
    },
    order () {
      const order = this.ordersStore.list.find(order => order.id == this.orderId)
      if (!order) {
        return false
      }
      return order
    },
    listPageHref () {
      let props = this.$router.resolve({
        name: 'OrdersPage',
      })
      return props.href
    },
    orderStatusList() {
      let newAr = [],
          ahead = true

      for(let key in this.ordersStore.statusList) {
        let item = this.ordersStore.statusList[key]
        newAr.push({
          ahead,
          current: this.order.status === key,
          name: item.name,
          status: key,
        })
        if (this.order.status === key) {
          ahead = false
        }
      }

      return newAr
    }
  },
  methods: {
    submit: authHelper.submit,
    async preSubmit($event, call) {
      this.$store.commit('setLoading', true)
      await this.submit($event, call)
      this.$store.commit('setLoading', false)
    },
    redirectToDetail(data) {
      console.log(data)
    },
    initSwiper() {
      new Swiper(this.$refs.swiper, {
        // configure Swiper to use modules
        modules: [Navigation, Pagination],
        slidesPerView: '4',
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        freeMode: true,
      })
    },
    statusTitle(row) {
      return this.ordersStore.statusList[row.status]['name']
    },
    statusStyle(row) {
      let backgroundColor = this.ordersStore.statusList[row.status]['color'],
          color = this.getTextColor(backgroundColor),
          border = '1px solid ' + this.darkenHexColor(backgroundColor, 10)
      return {
        backgroundColor,
        color,
        border,
        boxShadow: 'none',
      }
    },
    getTextColor: Helper.getTextColor,
    darkenHexColor: Helper.darkenHexColor,
    toCurrency: Helper.toCurrency,
    getProps(name, br) {
      if (this.order === false || !this.order.hasOwnProperty(name)) {
        return ''
      }

      if (br) {
        return this.order[name] ? this.order[name].replace(/\r\n/g, '<br>') : ''
      }

      return this.order[name]
    },
    getPhotoSrc(photo) {
      return window.location.protocol + '//' + window.location.hostname + photo.preview
    },
    changeFileValue(event) {
      let label = event.target.nextElementSibling
      label.innerText = event.target.files[0].name
      label.style.opacity = 1
    },
    fetchProducts() {
      axios.get('/ordersProducts', {
        params: {
          orderId: this.orderId
        }
      })
      .then(response => {
        if (response.data.success) {
          this.productList = response.data.items || [];
        } else {
          alert(response.data.errorMessage)
        }
      })
      .catch(error => {
        console.error('Произошла ошибка при запросе:', error);
      });
    }
  },
  mounted () {
    this.initSwiper()
    this.fetchProducts()
  }
}
</script>

<style lang="scss" scoped>
.button-add {
  width: 760px;
  display: flex;
  justify-content: flex-end
}

.inside {
  box-shadow: none;
}
.form .form-block:first-child {
  border-top: none;
}

.swiper-slide img {
  width: 150px;
}

.form-block__fields {
  //width: calc(100% - 450px);
}

label {
  cursor: text;
}

a.flex svg {
  width: 20px;
  height: 24px;
  display: inline-block;
  margin: 0 4px 0 0;
}

@media (max-width: 1360px) {
  .right-block {
    display: none!important;
  }
}

@media (max-width: 820px) {
  .button-add {
    width: auto;
  }

  h1 i,
  h1 .h1__current {
    display: none;
  }
}
</style>
