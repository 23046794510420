export default {
  namespaced: true,
  state: {
    order: {},
    type: '', // acceptOrder, giveOrder, acceptReturn, giveReturn
  },
  getters: {},
  mutations: {
    setOrder (state, data) {
      state.order = data
    },
    setType(state, data) {
      state.type = data
    }
  },
}
