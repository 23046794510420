import axios from 'axios'

export default {
  namespaced: true,
  state: {
    requisites: {},
    typesOwnership: {},
    ooo: null
  },
  getters: {},
  actions: {
    async getData (context) {
      const response = await axios.get('/user/requisites')
      await context.commit('setData', response.data)
      return response
    },
  },
  mutations: {
    setData (state, data) {
      if (data.hasOwnProperty('requisites')) {
        state.requisites = data.requisites
      }
      if (data.hasOwnProperty('typesOwnership')) {
        state.typesOwnership = data.typesOwnership
      }
      if (data.hasOwnProperty('ooo')) {
        state.ooo = data.ooo
      }
    },
    setField (state, data) {
      if (!data.hasOwnProperty('key') || !data.hasOwnProperty('value')) {
        return
      }
      state.requisites[data.key] = data.value
    },
  },
}
