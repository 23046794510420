<template>
  <h1>Регистрация</h1>
  <form @submit.prevent="submit" class="form auth-form">
    <div v-if="successMessage" class="alert alert--success mb-4">
      {{ successMessage }}
    </div>
    <label class="mb-4">
      Фамилия<br>
      <input v-model="lastName" @blur="blur($event, 'lastName')" :class="getClassInput('lastName')" type="text" name="lastName"
             placeholder="Иванов">
      <span class="form__error-message" v-if="v$.lastName.$error">Обязательно для заполнени</span>
    </label>
    <label class="mb-4">
      Имя<br>
      <input v-model="name" @blur="blur($event, 'name')" :class="getClassInput('name')" type="text" name="name"
             placeholder="Иван">
      <span class="form__error-message" v-if="v$.name.$error">Обязательно для заполнени</span>
    </label>
    <label class="mb-4">
      E-mail<br>
      <input v-model="email" @blur="blur($event, 'email')" :class="getClassInput('email')" type="text"
             name="email" placeholder="ivan@mail.ru">
      <span class="form__error-message" v-if="v$.email.$error">E-mail адрес указан неверно</span>
    </label>
    <label class="mb-4">
      Телефон<br>
      <input v-model="phone" @blur="blur($event, 'phone')" :class="getClassInput('phone')" type="text"
             v-maska data-maska="+7 (###) ###-##-##" name="phone">
      <span class="form__error-message" v-if="v$.phone.$error">Телефон указан неверно</span>
    </label>
    <label class="mb-4">
      Пароль<br>
      <input v-model="password" @blur="blur($event, 'password')" :class="getClassInput('password')" type="password"
             name="password">
      <span class="form__error-message" v-if="v$.password.$error">Пароль должен быть не менее 6 символов</span>
    </label>
    <label class="mb-4">
      Подтверждение пароля<br>
      <input v-model="confirmPassword" @blur="blur($event, 'confirmPassword')" :class="getClassInput('confirmPassword')"
             type="password" name="confirmPassword">
      <span class="form__error-message" v-if="v$.confirmPassword.$error">Пароли не совпадают</span>
    </label>
    <input type="hidden" name="lk" value="Y">
    <label class="flex items-baseline mb-2">
      <input class="mr-2 mt-1" type="checkbox" v-model="agreements">
      <div class="leading-4">
        Я принимаю <a href="#">Пользовательское соглашение</a><br>
        и <a href="#">политику конфиденциальности</a>
      </div>

    </label>
    <span class="form__error-message mb-3 ml-5" v-if="v$.agreements.$error">Требуется принять соглашения</span>
    <button type="submit" class="base-button">Зарегистрироваться</button>
    <div class="text-center mt-2">
      <a @click="$emit('change-page', 'SignIn')" class="dashed">&larr;&nbsp;Авторизация</a>
    </div>
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, minLength, required, sameAs } from '@vuelidate/validators'
import authHelper from '@/classes/formHelper'

export default {
  name: 'SignUp',
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  data () {
    return {
      name: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      agreements: true,
      successMessage: null,
      url: '/user/signUp',
      redirectAfterSuccess: '/',
    }
  },
  validations () {
    return {
      /*fio: {
        required,
        minLength: minLength(4),
        fewWords: value => !value ? false : value.toString().trim().indexOf(' ') > 1,
      },*/
      name: { required },
      lastName: { required },
      email: { required, email, $autoDirty: false },
      phone: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, minLength: minLength(6), sameAsPassword: sameAs(this.password) },
      agreements: {
        accepted: val => val === true
      }
    }
  },
  watch: {
    phone (value) {
      if (value === '8') {
        this.phone = '+7 ('
      }
      else if (value === '+7 (8') {
        this.phone = '+7 (9'
      }
    },
  },
  methods: {
    blur: authHelper.blur,
    getClassInput: authHelper.getClassInput,
    submit: authHelper.submit,
  },
}
</script>
